// eslint-disable-next-line @typescript-eslint/no-var-requires
const packageJson = require('../../package.json')

export const environment = {
    production: true,
    package: {
        version: packageJson.version,
        name: packageJson.name,
    },
    sentry: {
        dsn: {
            'wl-svsdb': 'https://fa41243cc6824a6fa2c2f940172112a9@sentry.io/1442128',
        },
    },
    captcha: {
        active: false,
    },
    backendUrl: '/api',
}
