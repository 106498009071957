import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, Router, RouterLink } from '@angular/router'
import { AuthService } from 'app/_services/auth.service'
import { PublicService } from 'app/_services/public.service'
import { ToastrService } from 'ngx-toastr'
import { take } from 'rxjs/operators'
import { ReactiveFormsModule, FormsModule } from '@angular/forms'
import { NgIf } from '@angular/common'

@Component({
    selector: 'app-auth-init',
    templateUrl: `./init.component.html`,
    styleUrls: [`./init.component.css`],
    standalone: true,
    imports: [NgIf, ReactiveFormsModule, FormsModule, RouterLink],
})
export class InitComponent implements OnInit {
    paramEmail
    paramRecover

    password
    passwordError

    recoverCorrect
    recoverSent

    constructor(
        private authService: AuthService,
        private publicService: PublicService,
        private router: Router,
        private activedRoute: ActivatedRoute,
        private toastrService: ToastrService
    ) {}

    ngOnInit() {
        this.activedRoute.params.pipe(take(1)).subscribe((params) => {
            this.paramEmail = params.email
            this.paramRecover = params.recover
            this.publicService.recoverLogin(params.email, params.recover).subscribe((res) => {
                this.recoverCorrect = res.data.recover.correct
            })
        })
    }

    setPassword() {
        const password = this.password
        const regex = new RegExp('(?=^.{8,}$)(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])(?=.*[^A-Za-z0-9]).*')
        if (!password || !regex.test(password))
            return (this.passwordError =
                'Wachtwoord moet minstens 8 tekens lang zijn en 1 hoofdletter, 1 kleine letter, 1 speciaal teken en 1 nummer bevatten')
        else this.passwordError = ''

        this.publicService.recoverSetPassword(this.paramEmail, password, this.paramRecover).subscribe(
            (res) => {
                // Check if we can login
                if (res.data.recover.correct === true) {
                    this.authService.setAuthData(res.data)
                    return this.navigateLogin()
                }
            },
            (err) => this.toastrService.error(err.message, err.error)
        )
    }

    navigateLogin() {
        const isMobileUser = ['mover'].includes(this.authService.user?.role?.id)
        if (isMobileUser) this.router.navigate(['/mobile/order-today'])
        else this.router.navigate(['/web/search/input'])
    }

    forgot() {
        this.publicService.forgot({ email: this.paramEmail }).subscribe((res) => {
            console.log(res)
            this.recoverSent = true
        })
    }
}
