import { DOCUMENT } from '@angular/common'
import { Inject, Injectable } from '@angular/core'

@Injectable({ providedIn: 'root' })
export class SharedService {
    constructor(@Inject(DOCUMENT) private document) {}

    faviconSet(companyNameId) {
        const favicons = [
            {
                rel: `apple-touch-icon`,
                sizes: `144x144`,
                href: `/assets/favicons-${companyNameId}/apple-touch-icon.png`,
            },
            {
                rel: `icon`,
                type: `image/png`,
                sizes: `32x32`,
                href: `/assets/favicons-${companyNameId}/favicon-32x32.png`,
            },
            {
                rel: `icon`,
                type: `image/png`,
                sizes: `16x16`,
                href: `/assets/favicons-${companyNameId}/favicon-16x16.png`,
            },
            {
                rel: `manifest`,
                href: `/assets/favicons-${companyNameId}/manifest.json`,
            },
            {
                rel: `mask-icon`,
                href: `/assets/favicons-${companyNameId}/safari-pinned-tab.svg`,
                color: `#a68100`,
            },
            {
                rel: `shortcut icon`,
                href: `/assets/favicons-${companyNameId}/favicon.ico`,
            },
        ]

        favicons.forEach((favicon, index) => {
            const elemId = `favicon_${index}`
            const elem = this.document.getElementById(elemId)

            // Check if elem exists
            if (!elem) return console.warn(`Whoops, we could not find elem with id: ${elemId}`)

            Object.keys(favicon).forEach((attr) => elem.setAttribute(attr, favicon[attr]))
        })
    }
}
