// 2.0.0 With interceptor
import { Location } from '@angular/common'
import { HttpClient } from '@angular/common/http'
import { Inject, Injectable } from '@angular/core'
import { FormGroup } from '@angular/forms'
import { Router } from '@angular/router'
import { BACKEND_URL } from 'main'
import { ToastrService } from 'ngx-toastr'
import { Observable } from 'rxjs'
import { catchError, tap } from 'rxjs/operators'

@Injectable({
    providedIn: 'root',
})
export class RequestService {
    constructor(
        @Inject(BACKEND_URL) private backendUrl: string,
        private router: Router,
        private location: Location,
        private httpClient: HttpClient,
        public toastrService: ToastrService
    ) {}

    // Http related functions
    //
    get(url, opts = {}, reqOpts: ReqOptions = null): Observable<ValidResponse> {
        const req = this.httpClient.get(`${this.backendUrl}${url}`, opts)
        return this.doPipe(req, reqOpts)
    }
    post(url, data, opts = {}, reqOpts: ReqOptions = null): Observable<ValidResponse> {
        const req = this.httpClient.post(`${this.backendUrl}${url}`, data, opts)
        return this.doPipe(req, reqOpts)
    }
    patch(url, data, opts = {}, reqOpts: ReqOptions = null): Observable<ValidResponse> {
        const req = this.httpClient.patch(`${this.backendUrl}${url}`, data, opts)
        return this.doPipe(req, reqOpts)
    }
    delete(url, opts = {}, reqOpts: ReqOptions = null): Observable<ValidResponse> {
        const req = this.httpClient.delete(`${this.backendUrl}${url}`, opts)
        return this.doPipe(req, reqOpts)
    }

    doPipe(req, reqOpts): Observable<ValidResponse> {
        if (!reqOpts) return req

        const tapToast = tap((res) => {
            const t = reqOpts.resolve.toastr
            const title = t.title
            const message = t.message || res['message']
            this.toastrService.success(message, title).onTap.subscribe({ next: t.onTap })
        })
        const catchToast = catchError((err) => {
            const t = reqOpts.reject.toastr
            const title = t.title
            const message = t.message || err.error.message
            this.toastrService.warning(message, title).onTap.subscribe({ next: t.onTap })
            throw err
        })
        const tapNavigate = tap((_) => {
            if (reqOpts.resolve.navigate.to) this.router.navigate([reqOpts.resolve.navigate.to])
            if (reqOpts.resolve.navigate.back) this.location.back()
        })
        const catchNavigate = catchError((err) => {
            if (reqOpts.resolve.navigate.to) this.router.navigate([reqOpts.resolve.navigate.to])
            if (reqOpts.resolve.navigate.back) this.location.back()
            throw err
        })

        const pipe = []
        if (reqOpts.resolve.toastr) pipe.push(tapToast)
        if (reqOpts.resolve.navigate) pipe.push(tapNavigate)
        if (reqOpts.reject.toastr) pipe.push(catchToast)
        if (reqOpts.reject.navigate) pipe.push(catchNavigate)

        return req.pipe(...pipe)
    }
}

export interface ReqOptionsToastr {
    messageReponse?: boolean
    message?: string
    title?: string
    onTap?(): any
}

export interface ReqOptionsNavigate {
    to?: string
    back?: boolean
}

export interface ReqOptions {
    resolve?: {
        toastr?: ReqOptionsToastr
        navigate?: ReqOptionsNavigate
        form?: FormGroup
    }
    reject?: {
        toastr?: ReqOptionsToastr
        navigate?: ReqOptionsNavigate
    }
}

export interface ValidResponse {
    failure: any
    error: any
    warning: any

    data: any
    meta: any
    message: any
}
