const eventColorClasses = {
    // State colors
    ['auto-option']: 'warning',
    option: 'warning',
    executed: 'info',
    completed: 'info',
    booked: 'secondary',
    // Other colors
    filled: 'success',
    error: 'danger',
    // Bootstrap colors
    primary: 'primary',
    secondary: 'secondary',
    success: 'success',
    danger: 'danger',
    warning: 'warning',
    info: 'info',
    light: 'light',
    dark: 'dark',
}

const hmId = '5b1e64188c14551290f57982'
const getCompanyIdFromIds = (companyIds) => {
    if (typeof companyIds === 'string') return companyIds
    if (companyIds.includes(hmId)) return hmId
    return companyIds[0]
}

const getEventColorForCompany = (colorClassId, companyIds) => {
    const companyId = getCompanyIdFromIds(companyIds)
    const colorSuffix = companyId === hmId ? '' : '-bg-subtle'
    return `${eventColorClasses[colorClassId]}${colorSuffix}`
}

module.exports = {
    eventColorClasses,
    getCompanyIdFromIds,
    getEventColorForCompany,
}
