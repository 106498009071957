const skillIconObject = {
    captain: {
        icon: 'user-hard-hat',
        char: '*',
    },
    busDriver: {
        icon: 'truck',
        char: '*',
    },
    liftDriver: {
        icon: 'lift-driver',
        char: '^',
    },
    liftOperator: {
        icon: 'moving-lift',
        char: '%',
    },
    handyman: {
        icon: 'tools',
        char: '$',
    },
}

module.exports = {
    skillIconObject,
}
