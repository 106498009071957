<div id="login" *ngIf="loginForm">
    <div class="col-12 col-sm-10 offset-sm-1 col-md-8 offset-md-2 col-lg-6 offset-lg-3">
        <div class="col-12 col-sm-8 offset-sm-2 col-md-10 offset-md-1 col-lg-8 offset-lg-2">
            <div class="card card-body bg-light p-0" *ngIf="company && state.signUpState">
                <img class="img-responsive" [src]="'/image/company/' + company?._id + '/banner/icon_256' | image" alt="" />
            </div>

            <form class="card card-body bg-light" [formGroup]="loginForm" novalidate autocomplete="off">
                <img src="{{ state.imageUrl }}" class="avatar img-responsive" />

                <!-- Back btn -->
                <a class="btn btn-back btn-link ps-2" *ngIf="state.showBackButton" (click)="doLogin()">
                    <fa-icon [icon]="['fal', 'angle-left']" size="2x" [fixedWidth]="true"></fa-icon>
                </a>

                <legend *ngIf="state.signUpState">Inlog gegevens</legend>

                <!-- Email field -->
                <div class="form-group mb-3">
                    <input
                        id="inputEmail"
                        class="form-control"
                        placeholder="Vul je e-mail adres in"
                        formControlName="email"
                        [class.is-invalid]="state.email.message ? true : null"
                        [attr.readonly]="state.emailReadOnly ? true : null"
                        (keydown.enter)="onSubmit()"
                        value=""
                        data-cy="username"
                        autocomplete="username"
                    />
                    <span class="invalid-feedback">
                        {{ state.email.message }}
                    </span>
                </div>

                <!-- Password -->
                <div class="form-group mb-3" [attr.hidden]="state.showPassword ? null : true">
                    <input
                        id="inputPassword"
                        class="form-control"
                        type="password"
                        maxlength="100"
                        placeholder="Wachtwoord"
                        formControlName="password"
                        [class.is-invalid]="state.password.message ? true : null"
                        (keydown.enter)="onSubmit()"
                        data-cy="password"
                        autocomplete="current-password"
                    />
                    <span class="invalid-feedback">{{ state.password.message }}</span>
                </div>

                <button
                    class="btn btn-primary btn-block mb-3"
                    [class.disabled]="state.loading || (state.signUpState && !loginForm.valid)"
                    (click)="onSubmit()"
                    data-cy="submit"
                >
                    <fa-icon [icon]="['fal', 'spinner-third']" [spin]="true" [fixedWidth]="true" *ngIf="state.loading"></fa-icon>
                    <span *ngIf="!state.loading">{{ state.buttonLabel }}</span>
                </button>

                <div class="d-flex justify-content-center">
                    <asl-google-signin-button type="standard" size="large" shape="pill"></asl-google-signin-button>
                </div>
            </form>

            <div class="text-center mt-3 mb-3" *ngIf="state && state.showPassword">
                <a *ngIf="!state.forgot.correct" (click)="forgot()" class="btn btn-link"> Wachtwoord vergeten? </a>
                <a aria-disabled="true" *ngIf="state.forgot.correct" class="btn btn-link disabled">
                    Je hebt een link in je mail ontvangen!
                </a>
            </div>
        </div>
    </div>
</div>
