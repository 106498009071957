<div class="col-12 col-lg-6 offset-lg-3 mt-5 px-5">
    <h3 class="text-center my-3">Welkom bij SVS</h3>

    <!-- Check if recover is correct -->
    <div *ngIf="!recoverCorrect">
        <!-- Warning -->
        <div class="alert alert-warning" *ngIf="!recoverSent">
            <h4>Oops!</h4>
            <p>
                Deze reset code is al gebruikt, druk opnieuw op wachtwoord vergeten.
                <a (click)="forgot()" class="alert-link">Wachtwoord vergeten</a>
            </p>
        </div>

        <!-- Forgot -->
        <div class="alert alert-success" *ngIf="recoverSent">
            <h4>Perfect!</h4>
            <p>We hebben een nieuwe link naar je mail gestuurd!</p>
        </div>
    </div>

    <!-- Steps -->
    <div class="card bg-light" *ngIf="recoverCorrect">
        <!-- Password -->
        <form class="card-body" (submit)="setPassword()">
            <p class="lead">Kies een wachtwoord</p>

            <div class="form-group" [class.has-error]="passwordError">
                <input [(ngModel)]="password" name="password" class="form-control" type="password" placeholder="Password" />
                <span class="text-danger">{{ passwordError }}</span>
                <small class="form-text text-muted"
                    >Wachtwoord moet minstens 8 tekens lang zijn en 1 hoofdletter, 1 kleine letter, 1 speciaal teken en 1 nummer
                    bevatten</small
                >
            </div>

            <div class="d-flex justify-content-end">
                <button class="btn btn-block btn-primary text-white mt-3" (click)="setPassword()">Opslaan</button>
            </div>
        </form>
    </div>

    <a routerLink="/" class="btn btn-block btn-link text-center mt-3"> Ga naar login pagina </a>
</div>
