const availableMaterial = [
    {
        value: 'captain',
        name: 'Project',
        abbr: 'PV',
    },
    {
        value: 'busDriver',
        name: 'Driver',
        abbr: 'Dr',
    },
    {
        value: 'liftDriver',
        name: 'Liftdriver',
        abbr: 'LDr',
    },
    {
        value: 'mover',
        name: 'Sjouwer',
        abbr: 'Sj',
    },
    {
        value: 'handyman',
        name: 'Handy',
        abbr: 'H',
    },
    {
        value: 'bus',
        name: 'Bus',
        abbr: 'Bus',
    },
    {
        value: 'car',
        name: 'Auto',
        abbr: 'Auto',
    },
    {
        value: 'lift',
        name: 'Lift',
        abbr: 'Lift',
    },
    {
        value: 'movingLiftXl',
        name: 'Lift XL',
        abbr: 'L XL',
    },
]
module.exports = {
    availableMaterial,
}
