import { NgModule } from '@angular/core'
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome'
import { faCcAmazonPay, faGoogle, faSearchengin, faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import {
    faAddressBook,
    faAngleDown,
    faAngleLeft,
    faAngleRight,
    faAngleUp,
    faArchive,
    faAsterisk,
    faBan,
    faBars,
    faBoxOpen,
    faBriefcase,
    faBrowser,
    faBullseyeArrow,
    faCalculator,
    faCalendar,
    faCalendarCheck,
    faCalendarDay,
    faCalendarMinus,
    faCamera,
    faCar,
    faCarCrash,
    faCaretDown,
    faCaretLeft,
    faCaretRight,
    faCaretUp,
    faCertificate,
    faChalkboardTeacher,
    faChartBar,
    faCheck,
    faCheckCircle,
    faCheckSquare,
    faChevronDown,
    faChevronRight,
    faCircle,
    faCircleNotch,
    faClipboard,
    faClipboardList,
    faClipboardListCheck,
    faClipboardUser,
    faClock,
    faClone,
    faCloudUpload,
    faCode,
    faCodeBranch,
    faColumns,
    faComment,
    faComments,
    faConstruction,
    faCreditCard,
    faDatabase,
    faDownload,
    faEdit,
    faEllipsisH,
    faEnvelope,
    faEnvelopeSquare,
    faEraser,
    faEuroSign,
    faExclamation,
    faExclamationCircle,
    faExclamationTriangle,
    faEye,
    faEyeSlash,
    faFile,
    faFileAlt,
    faFileInvoice,
    faFileUpload,
    faFrownOpen,
    faGavel,
    faGift,
    faGifts,
    faGlobe,
    faGraduationCap,
    faHistory,
    faHome,
    faInboxIn,
    faInboxOut,
    faInfo,
    faInfoCircle,
    faLink,
    faList,
    faLock,
    faMagic,
    faMailBulk,
    faMapMarked,
    faMapSigns,
    faMinus,
    faMinusSquare,
    faMobile,
    faMoneyBill,
    faPaperPlane,
    faPaperclip,
    faPauseCircle,
    faPenSquare,
    faPencil,
    faPercent,
    faPercentage,
    faPhone,
    faPlus,
    faPlusSquare,
    faPrint,
    faQrcode,
    faQuestionCircle,
    faRepeat,
    faReplyAll,
    faRobot,
    faSave,
    faSearch,
    faSearchLocation,
    faSeedling,
    faShare,
    faShoppingCart,
    faSickle,
    faSignOut,
    faSitemap,
    faSmile,
    faSms,
    faSort,
    faSortDown,
    faSortUp,
    faSparkles,
    faSpinner,
    faSpinnerThird,
    faSquare,
    faStreetView,
    faSurprise,
    faSync,
    faTicket,
    faTimes,
    faTimesCircle,
    faTools,
    faTrailer,
    faTrashAlt,
    faTruck,
    faTruckCouch,
    faUndo,
    faUnlink,
    faUnlock,
    faUpload,
    faUser,
    faUserChart,
    faUserCircle,
    faUserCrown,
    faUserHardHat,
    faUserInjured,
    faUserShield,
    faUserSlash,
    faWarehouse,
} from '@fortawesome/pro-light-svg-icons'
import {
    faCircleCheck,
    faCircleSmall,
    faInbox,
    faBars as fasBars,
    faCircle as fasCircle,
    faExclamation as fasExclamation,
    faExclamationTriangle as fasExclamationTriangle,
    faStar as fasStar,
    faStarHalfAlt as fasStarHalfAlt,
    faArrowRight as fasArrowRight,
} from '@fortawesome/pro-solid-svg-icons'
import { faBoxArchive, faStar } from '@fortawesome/pro-thin-svg-icons'

@NgModule({
    imports: [],
    exports: [FontAwesomeModule],
})
export class SharedFontAwesomeModule {
    constructor(private library: FaIconLibrary) {
        library.addIcons(
            faAngleLeft,
            faCircleNotch,
            faList,
            faInfo,
            faSignOut,
            faSync,
            faBoxOpen,
            faTruck,
            faPhone,
            faUser,
            faTimes,
            faPlus,
            faPauseCircle,
            faComment,
            faCheck,
            faPencil,
            faTrashAlt,
            faEraser,
            faCaretRight,
            faCaretLeft,
            faMinus,
            faCalendar,
            faClock,
            faCreditCard,
            faSquare,
            faUserCircle,
            faEnvelope,
            faTicket,
            faChartBar,
            faExclamationTriangle,
            faClipboard,
            faArchive,
            faShoppingCart,
            faExclamation,
            faBan,
            faSearch,
            faSave,
            faEye,
            faEyeSlash,
            faCaretDown,
            faCircle,
            faReplyAll,
            faStar,
            faPaperclip,
            faCode,
            faFile,
            faLink,
            faCaretUp,
            faShare,
            faMagic,
            faMobile,
            faMapSigns,
            faMoneyBill,
            faGift,
            faUndo,
            faClone,
            faEdit,
            faCalculator,
            faPlusSquare,
            faCloudUpload,
            faRepeat,
            faAngleUp,
            faAngleDown,
            faMinusSquare,
            faPenSquare,
            faStreetView,
            faMapMarked,
            faPrint,
            faAngleRight,
            faDownload,
            faSort,
            faSortUp,
            faSortDown,
            faExclamationCircle,
            faLock,
            faCertificate,
            faUserCrown,
            faEllipsisH,
            faGlobe,
            faCheckSquare,
            faGavel,
            faUnlink,
            faAsterisk,
            faCheckCircle,
            faBars,
            faCalendarMinus,
            faCalendarCheck,
            faEuroSign,
            faFileAlt,
            faEnvelopeSquare,
            faUserShield,
            faUnlock,
            faSpinnerThird,
            faGoogle,
            faWhatsapp,
            fasStar,
            faQuestionCircle,
            faComments,
            faAddressBook,
            faChalkboardTeacher,
            faCar,
            faTruckCouch,
            faSitemap,
            faClipboardListCheck,
            faTools,
            faClipboardList,
            faPauseCircle,
            faCcAmazonPay,
            faColumns,
            faHistory,
            faSearchLocation,
            faFileUpload,
            faInfoCircle,
            faInbox,
            faSurprise,
            faFrownOpen,
            faExclamationTriangle,
            fasExclamationTriangle,
            fasExclamation,
            fasStarHalfAlt,
            faSmile,
            faCertificate,
            faTrailer,
            faUserSlash,
            faSearchengin,
            faPercent,
            faCamera,
            faRobot,
            faPercentage,
            faClipboardUser,
            faUserHardHat,
            faMailBulk,
            faCarCrash,
            faUserInjured,
            faCalendarDay,
            faGifts,
            faPaperPlane,
            faDatabase,
            faSeedling,
            faSickle,
            faConstruction,
            faHome,
            faSpinner,
            faUserHardHat,
            faBullseyeArrow,
            fasArrowRight,
            faInboxIn,
            faInboxOut,
            faBoxArchive,
            faUserChart,
            fasBars,
            faUpload,
            faBriefcase,
            faCircleCheck,
            fasCircle,
            faSms,
            faBrowser,
            faTimesCircle,
            faFileInvoice,
            faCodeBranch,
            faQrcode,
            faSparkles,
            faGraduationCap,
            faChevronDown,
            faChevronRight,
            faCircleSmall,
            faWarehouse
        )
    }
}
