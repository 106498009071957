const departments = [
    {
        value: 'mover',
        name: 'Verhuizer',
    },
    {
        value: 'backup',
        name: 'Backup',
    },
    {
        value: 'compensation-rotterdam',
        name: 'Compensatie Rotterdam',
    },
    {
        value: 'compensation-utrecht',
        name: 'Compensatie Utrecht',
    },
    {
        value: 'compensation-eindhoven',
        name: 'Compensatie Eindhoven',
    },
    {
        value: 'compensation-nijmegen',
        name: 'Compensatie Nijmegen',
    },
    {
        value: 'compensation-zwolle',
        name: 'Compensatie Zwolle',
    },
    {
        value: 'illness',
        name: 'Ziektewet',
    },
    {
        value: 'mover-board-projects',
        name: 'Verhuizer (directie werkzaamheden)',
    },
    {
        value: 'sales',
        name: 'Sales',
    },
    {
        value: 'manager',
        name: 'Manager',
    },
    {
        value: 'sd',
        name: 'SD',
    },
    {
        value: 'board',
        name: 'Directie',
    },
    {
        value: 'board-projects',
        name: 'Directie werkzaamheden',
    },
    {
        value: 'mt',
        name: 'MT',
    },
    {
        value: 'garage',
        name: 'Werkplaats',
    },
    {
        value: 'marketing',
        name: 'Marketing',
    },
    {
        value: 'aftersales',
        name: 'Aftersales',
    },
    {
        value: 'accounting',
        name: 'Boekhouding',
    },
    {
        value: 'projects',
        name: 'Projecten',
    },
    {
        value: 'it',
        name: 'IT',
    },
    {
        value: 'legal',
        name: 'Legal',
    },
    {
        value: 'viewing',
        name: 'Bezichtiging',
    },
    {
        value: 'viewing-online',
        name: 'Online bezichtiging',
    },
]

module.exports = {
    departments,
}
