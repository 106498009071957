import { Component, Inject, OnInit } from '@angular/core'
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms'
import { Router } from '@angular/router'
import { AuthService } from 'app/_services/auth.service'
import { PublicService } from 'app/_services/public.service'
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome'
import { JsonPipe, NgIf } from '@angular/common'
import { ImagePipe } from 'app/shared/pipes/image.pipe'
import { BACKEND_URL } from 'main'
import { GoogleSigninButtonModule } from '@abacritt/angularx-social-login'

@Component({
    selector: 'login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css'],
    standalone: true,
    imports: [NgIf, ReactiveFormsModule, FontAwesomeModule, ImagePipe, JsonPipe, GoogleSigninButtonModule],
})
export class LoginComponent implements OnInit {
    loginForm: FormGroup = null

    // Default company
    company = null

    // Holds the current state of the form
    state = {
        // Personal data
        name: {
            first: {},
            middle: {},
            last: {},
        },
        gender: {},

        email: { correct: null, message: null },
        password: { correct: null, message: null },
        forgot: { correct: null, message: null },

        imageUrl: '/assets/image/user/placeholder-profile.png',

        // Meta
        showLabels: false,
        showBackButton: false,
        showPassword: false,
        emailReadOnly: false,
        signUpState: false,
        buttonLabel: 'verder',

        loading: false,
    }

    constructor(
        @Inject(BACKEND_URL) private backendUrl: string,
        private publicService: PublicService,
        private authService: AuthService,
        private formBuilder: FormBuilder,
        private router: Router // private socialAuthService: SocialAuthService
    ) {}

    ngOnInit() {
        // Check if user is logged in
        this.authService.authSource.subscribe((authData) => {
            if (authData) this.authService.navigateHome()
        })

        this.initForm()
    }

    initForm() {
        const fb = this.formBuilder

        this.loginForm = fb.group({
            email: [''],
            password: [''],
        })

        this.doFocus('inputEmail')
    }

    doLogin() {
        this.state.showLabels = false
        this.state.showBackButton = false
        this.state.showPassword = false
        this.state.emailReadOnly = false
        this.state.signUpState = false
        this.state.buttonLabel = 'verder'
        this.loginForm.get('password').setValue('')
        this.hideError()
        this.doFocus('inputEmail')
    }

    doPassword() {
        this.state.showLabels = false
        this.state.showBackButton = true
        this.state.showPassword = true
        this.state.emailReadOnly = true
        this.state.signUpState = false
        this.state.buttonLabel = 'inloggen'
        this.hideError()
        this.doFocus('inputPassword')
    }

    doFocus(elementId, timeout = 0) {
        setTimeout(() => {
            // Get element
            const element = document.getElementById(elementId)

            //If found focus, otherwise second attempt
            if (element) element.focus()
            else if (timeout === 0) this.doFocus(elementId, 25)
        }, timeout)
    }

    forgot() {
        this.publicService.forgot({ email: this.loginForm.value.email }).subscribe((res) => {
            this.state.forgot = res.data.forgot
        })
    }

    onSubmit() {
        this.loading()
        const data = this.loginForm.value
        if (this.state.showPassword)
            return this.publicService.login(data).subscribe(this.handleLogin.bind(this), (err) => this.handleError(err, 'password'))

        this.publicService.email(data).subscribe(this.handleLogin.bind(this), (err) => this.handleError(err, 'email'))
    }

    handleLogin(res) {
        // Always hide error on response
        this.hideError()

        // Check if we can login
        if (res.data.password.correct === true) {
            this.authService.setAuthData(res.data)
            return this.authService.navigateHome()
        }

        // Show complete loading
        this.complete()

        if (res.data.email.correct === true) {
            this.showImage(res.data.user.image)
            this.setEmail(res.data.user.email.value)
            this.doPassword()
        }
    }

    private complete() {
        this.state.loading = false
    }

    private loading() {
        this.state.loading = true
    }

    private hideError() {
        this.state.email.message = null
        this.state.password.message = null
    }

    private showError(type, message) {
        if (type === 'password') {
            this.state.password.correct = false
            this.state.password.message = message
            return
        }

        this.state.email.correct = false
        this.state.email.message = message
        return
    }

    private handleError(err, type) {
        console.log(err, type)
        // Show complete loading
        this.complete()

        if (type) return this.showError(type, err.error.message)
    }

    private setEmail(email) {
        this.loginForm.get('email').setValue(email)
        this.state.email.correct = true
    }

    private showImage(image) {
        this.state.imageUrl =
            image && image.profile
                ? `${this.backendUrl}/image/${image.profile}/icon_256`
                : '/assets/image/user/placeholder-profile-active.png'
    }
}
