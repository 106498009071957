import { DOCUMENT } from '@angular/common'
import { Inject, Injectable } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import * as Sentry from '@sentry/angular-ivy'
import { SocialAuthService, SocialUser } from '@abacritt/angularx-social-login'
import { PublicService } from './public.service'
import { environment } from 'environments/environment'
import { BehaviorSubject, Subject } from 'rxjs'

interface AuthData {
    user: any
    token: {
        value: string
        expiredAt: number
    }
}

@Injectable({ providedIn: 'root' })
export class AuthService {
    get user() {
        return this.authSource.value?.user
    }
    get token() {
        return this.authSource.value?.token
    }
    get isLoggedIn() {
        return !!this.user
    }
    authSource = new BehaviorSubject<AuthData | undefined>(undefined)
    private auth = new Subject<AuthData | undefined>()
    private authLocalStorageKey = environment.package.name + '-auth-2024-07'

    constructor(
        private router: Router,
        @Inject(DOCUMENT) private document,
        private socialAuthService: SocialAuthService,
        private publicService: PublicService,
        private activatedRoute: ActivatedRoute
    ) {
        this.auth.subscribe(this.authSource)

        const localStorageUser = this.findLocalStorageUser()
        this.setAuthData(localStorageUser)
        this.socialAuthWatch()
    }

    navigateHome() {
        const redirect = this.activatedRoute.snapshot.queryParams['redirect']
        if (redirect) return this.router.navigateByUrl(redirect)
        if (this.user?.role && ['mover'].includes(this.user.role.id)) return this.router.navigate(['/mobile/order-today'])
        this.router.navigate(['/web/search/input'])
    }

    socialAuthWatch() {
        this.socialAuthService.authState.subscribe((socialUser) => {
            if (socialUser) this.login(socialUser)
        })
    }

    async login(socialUser: SocialUser) {
        const data = { socialUser }
        this.publicService.loginGoogle(data).subscribe((res) => {
            this.setAuthData(res.data)
        })
    }

    setAuthData(authData) {
        if (!authData?.token?.value) return
        this.auth.next(authData)
        localStorage.setItem(this.authLocalStorageKey, JSON.stringify(authData))

        Sentry.setUser({
            id: authData.user._id,
            username: authData.user.name.full,
            email: authData.user.email.value,
        })
    }

    findLocalStorageUser() {
        try {
            const dataRaw = localStorage.getItem(this.authLocalStorageKey)
            return JSON.parse(dataRaw)
        } catch (ex) {
            console.log({ ex })
            this.clearLocalStorageUser()
        }
    }
    clearLocalStorageUser() {
        localStorage.removeItem(this.authLocalStorageKey)
    }

    async logout(redirect = null) {
        if (this.user) {
            try {
                this.clearLocalStorageUser()
                this.auth.next(undefined)
            } catch (ex) {
                console.error(ex)
            }
        }

        // See if we need to navigate
        const extras = redirect ? { queryParams: { redirect } } : undefined
        this.router.navigate([''], extras)
    }

    getToken() {
        return this.token?.value || ''
    }
}
