const paymentConditions = [
    {
        value: 'cash',
        name: 'Contant',
        code: '00',
        tag: [],
        icon: 'money-bill',
    },
    {
        value: 'invoice',
        name: 'Op factuur (consument)',
        code: '01',
        tag: ['incomplete'],
        icon: 'clock',
    },
    {
        value: 'refund',
        name: 'Restitutie',
        code: '03',
        tag: [],
        icon: 'undo',
    },
    {
        value: 'invoice-business-regular',
        name: 'Op factuur (zakelijk vast)',
        code: '04',
        tag: ['incomplete'],
        icon: 'clock',
    },
    {
        value: 'invoice-business',
        name: 'Op factuur (zakelijk)',
        code: '05',
        tag: ['incomplete'],
        icon: 'clock',
    },
    {
        value: 'pin',
        name: 'Pin',
        code: '14',
        tag: [],
        icon: 'credit-card',
    },
    {
        value: 'transfer',
        name: 'Betaallink',
        code: '15',
        tag: [],
        icon: 'sms',
    },
    {
        value: 'tip',
        name: 'Fooi (pin)',
        code: null,
        tag: [],
        icon: 'gift',
    },
]

const getPaymentConditionsByTag = (tag) => paymentConditions.filter((pc) => pc.tag.includes(tag))

module.exports = {
    paymentConditions,
    getPaymentConditionsByTag,
}
