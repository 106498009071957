const userRoles = [
    { value: 'customer', name: 'Klant', permission: 1 },
    { value: 'car', name: 'Auto', permission: 1 },
    { value: 'bus', name: 'Verhuiswagen', permission: 1 },
    { value: 'lift', name: 'Verhuislift', permission: 1 },
    { value: 'movingLiftXl', name: 'Verhuislift XL', permission: 1 },
    { value: 'tools', name: 'Gereedschap', permission: 1 },
    { value: 'mover', name: 'Verhuizer', permission: 2 },
    { value: 'manager', name: 'Manager', permission: 3 },
    { value: 'admin', name: 'Admin', permission: 4 },
    { value: 'super', name: 'Super', permission: 9 },
    { value: 'developer', name: 'Developer', permission: 99 },
]

module.exports = {
    userRoles,
}
