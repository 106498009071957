import { availableMaterial } from 'app/../../server/data/available-material'
import { departments } from 'app/../../server/data/departments'
import { paymentConditions } from 'app/../../server/data/payment-conditions'
import { pinTerminals } from 'app/../../server/data/pin-terminals'
import { skillIconObject } from 'app/../../server/data/skill-icons'
import { eventColorClasses, getEventColorForCompany, getCompanyIdFromIds } from 'app/../../server/data/event-colors'
import { userRoles } from 'app/../../server/data/user-roles'
import templateUniqueRoles from 'app/../../server/models/template/unique-roles'
import { filterUserSkillRef } from 'app/../../server/helpers/skill'
import { sortBy } from 'lodash'

const determineEnvironment = () => {
    const host = window.location.host
    if (host.includes('develop.')) return 'develop'
    if (host.includes('localhost:')) return 'local'
    return 'production'
}
export const environmentString = determineEnvironment()

interface ConfigOption {
    value: any
    name: any
    [keys: string]: any
}
const classificationTypes: ConfigOption[] = sortBy(
    [
        {
            value: 'international',
            name: 'Internationaal',
        },
        {
            value: 'm3',
            name: 'Inboedel',
        },
        {
            value: 'situation',
            name: 'Situatie',
        },
        {
            value: 'other',
            name: 'Anders',
        },
    ],
    'name'
)

const communicationPreferences: ConfigOption[] = sortBy(
    [
        {
            value: 'email',
            name: 'Email',
        },
        {
            value: 'phone',
            name: 'Telefoon',
        },
        {
            value: null,
            name: 'Geen',
        },
    ],
    'name'
)

const moverTypes: ConfigOption[] = sortBy(
    [
        {
            value: 'mover',
            name: 'Verhuizer',
        },
        {
            value: 'handyman',
            name: 'Handyman',
        },
        {
            value: 'captain',
            name: 'Projectverhuizer',
        },
        {
            value: 'test',
            name: 'Tester',
        },
    ],
    'name'
)

const taxCodes: ConfigOption[] = sortBy(
    [
        {
            value: 0,
            name: 'BTW vrij',
        },
        {
            value: 2,
            name: 'excl. BTW',
        },
        {
            value: 4,
            name: 'incl. BTW',
        },
    ],
    'name'
)

const templateKind: ConfigOption[] = sortBy(
    [
        {
            value: 'transactional',
            name: 'Transactioneel',
        },
        {
            value: 'service',
            name: 'Service',
        },
        {
            value: 'marketing',
            name: 'Marketing',
        },
    ],
    'name'
)

const changeReasons: any[] = sortBy(
    [
        {
            label: 'Ziek (ochtend)',
            allowed: false,
            value: 'morning-sick',
        },
        {
            label: 'Blessure (ochtend)',
            allowed: false,
            value: 'morning-injury',
        },
        {
            label: 'Prive (ochtend)',
            allowed: true,
            value: 'morning-private',
        },
        {
            label: 'Verslapen (ochtend)',
            allowed: false,
            value: 'morning-overslept',
        },
        {
            label: 'No-show (ochtend)',
            allowed: false,
            value: 'morning-no-show',
        },
        {
            label: 'Geen reden',
            allowed: false,
            value: 'no-reason',
        },
        {
            label: 'Bijgebeld',
            allowed: true,
            value: 'called',
        },
        {
            label: 'Ziek',
            allowed: true,
            value: 'sick',
        },
        {
            label: 'Blessure',
            allowed: true,
            value: 'injured',
        },
        {
            label: 'Prive',
            allowed: true,
            value: 'private',
        },
        {
            label: 'In overleg manager',
            allowed: true,
            value: 'management',
        },
        {
            label: 'Dagwissel',
            allowed: true,
            value: 'swap',
        },
        {
            label: 'Roosterfout',
            allowed: true,
            value: 'error',
        },
        {
            label: 'No-check',
            allowed: false,
            value: 'no-check',
        },
    ],
    'label'
)

const statesMail: any = sortBy(
    [
        {
            id: '*',
            labelHTML: 'Alle statussen',
            icon: { class: 'asterisk' },
        },
        {
            id: 'done',
            labelHTML: 'Behandeld',
            btnStyle: 'success',
            icon: { class: 'check-circle' },
        },
        {
            id: 'pending',
            labelHTML: 'Pending',
            btnStyle: 'info',
            icon: { class: 'sync' },
        },
        {
            id: 'open',
            labelHTML: 'Open',
            btnStyle: 'warning',
            icon: { class: 'circle' },
        },
    ],
    'labelHTML'
)

const orderworkTypes: any = sortBy(
    [
        {
            id: 'removal',
            labelHTML: 'Verhuisdag',
            icon: { class: 'truck-couch' },
        },
        {
            id: 'boxes',
            labelHTML: 'Dozenklus',
            icon: { class: 'box-open' },
        },
        {
            id: 'viewing',
            labelHTML: 'Bezichtiging',
            icon: { class: 'clipboard-user' },
        },
        {
            id: 'other',
            labelHTML: 'Anders',
            icon: { class: 'briefcase' },
        },
    ],
    'labelHTML'
)

const locationTypes: any = sortBy(
    [
        {
            id: 'dwellingHouse',
            labelHTML: 'Woonhuis',
            icon: { class: '' },
        },
        {
            id: 'appartment',
            labelHTML: 'Appartement',
            icon: { class: '' },
        },
        {
            id: 'studioHouse',
            labelHTML: 'Studio',
            icon: { class: '' },
        },
        {
            id: 'singleFamily',
            labelHTML: 'Eengezinswoning',
            icon: { class: '' },
        },
        {
            id: 'villa',
            labelHTML: 'Villa',
            icon: { class: '' },
        },
        {
            id: 'other',
            labelHTML: 'Anders',
            icon: { class: '' },
        },
        {
            id: 'stairs',
            labelHTML: 'Trap',
            icon: { class: '' },
        },
        {
            id: 'hoistup',
            labelHTML: 'Takelen',
            icon: { class: '' },
        },
        {
            id: 'liftInternal',
            labelHTML: 'Interne Lift',
            icon: { class: '' },
        },
        {
            id: 'liftExternal',
            labelHTML: 'Verhuislift',
            icon: { class: '' },
        },
    ],
    'labelHTML'
)

const limitingFactorTypes: any = [
    {
        name: 'Handyman foto',
        value: 'photoHandyman',
    },
    {
        name: 'Full service foto',
        value: 'photoFullService',
    },
    {
        name: 'Lift foto',
        value: 'photoLift',
    },
    {
        name: "Special(e) object(en) foto's",
        value: 'photoSpecialObject',
    },
    {
        name: 'Meerdaagse internationale verhuizing',
        value: 'internationalMultiDay',
    },
    {
        name: 'Order bevat een nog niet uitgevoerd of afgerond bezichtigingswerkorder',
        value: 'isBlockedBySiblingOrderwork',
        description: 'Deze limiterende factor wordt automatisch bepaald',
        isAutomatic: true,
    },
]

const statesTicket: any = [
    {
        id: 'ticket-new',
        name: {
            nl: 'Nieuw ticket',
        },
        labelHTML: 'Nieuw <br />ticket',
        icon: { class: 'plus-square' },
        acceptState: ['ticket-new'],
    },
    {
        id: 'ticket-faq',
        name: {
            nl: 'Vraag FAQ',
        },
        labelHTML: 'Vraag <br />FAQ',
        icon: { class: 'question-circle' },
        acceptState: ['ticket-new', 'ticket-form', 'ticket-faq', 'ticket-remainder', 'ticket-lead', 'ticket-viewing'],
    },
    {
        id: 'ticket-form',
        name: {
            nl: 'Aanvraag formulier',
        },
        labelHTML: 'Aanvraag <br />formulier',
        icon: { class: 'bars' },
        acceptState: ['ticket-new', 'ticket-form', 'ticket-faq', 'ticket-remainder', 'ticket-lead', 'ticket-viewing'],
    },
    {
        id: 'ticket-remainder',
        name: {
            nl: 'Categorie overig',
        },
        labelHTML: 'Categorie <br />overig',
        icon: { class: 'comments' },
        acceptState: ['ticket-new', 'ticket-form', 'ticket-faq', 'ticket-remainder', 'ticket-lead', 'ticket-viewing'],
    },
    {
        id: 'ticket-lead',
        name: {
            nl: 'Leads',
        },
        labelHTML: 'Leads',
        icon: { class: 'address-book' },
        acceptState: ['ticket-new', 'ticket-form', 'ticket-faq', 'ticket-remainder', 'ticket-lead', 'ticket-viewing'],
    },
    {
        id: 'ticket-viewing',
        name: {
            nl: 'Bezichtiging',
        },
        labelHTML: 'Bezichtiging',
        icon: { class: 'clipboard-user' },
        acceptState: ['ticket-new', 'ticket-form', 'ticket-faq', 'ticket-remainder', 'ticket-lead', 'ticket-viewing'],
    },
]

const statesOrder: any = [
    {
        id: 'new',
        name: {
            nl: 'Nieuwe aanvraag',
        },
        labelHTML: 'Nieuwe <br />aanvraag',
        icon: { class: 'plus-square' },
        btnStyle: 'primary',
        acceptState: ['new'],
    },
    {
        id: 'addressIncomplete',
        name: {
            nl: 'Adres onduideijk',
        },
        labelHTML: 'Adres <br />onduidelijk',
        icon: { class: 'comment' },
        btnStyle: 'primary',
        acceptState: ['new', 'addressIncomplete'],
    },
    {
        id: 'householdIncomplete',
        name: {
            nl: 'Inboedel onduidelijk',
        },
        labelHTML: 'Inboedel <br />onduidelijk',
        icon: { class: 'comment' },
        btnStyle: 'primary',
        acceptState: ['new', 'householdIncomplete', 'option', 'auto-option'],
    },
    {
        id: 'shortNotice',
        name: {
            nl: 'Kortdag',
        },
        labelHTML: 'Kortdag',
        icon: { class: 'comment' },
        btnStyle: 'primary',
        acceptState: ['new', 'shortNotice'],
    },
    {
        id: 'queue',
        name: {
            nl: 'Wachtrij',
        },
        labelHTML: 'Wachtrij',
        icon: { class: 'bars' },
        btnStyle: 'primary',
        acceptState: [
            'new',
            'queue',
            'addressIncomplete',
            'householdIncomplete',
            'shortNotice',
            'estimate',
            'option',
            'auto-option',
            'booked',
        ],
    },
    {
        id: 'option',
        name: {
            nl: 'Optie',
        },
        labelHTML: 'Optie',
        icon: { class: 'minus-square' },
        btnStyle: 'primary',
        acceptState: [
            'new',
            'addressIncomplete',
            'householdIncomplete',
            'shortNotice',
            'auto-option',
            'option',
            'queue',
            'estimate',
            'responseless',
            'cancellation',
            'booked',
            'cancellationWithCosts',
        ],
    },
    {
        id: 'auto-option',
        name: {
            nl: 'AM - Optie',
        },
        labelHTML: 'AM - Optie',
        icon: { class: 'robot' },
        btnStyle: 'primary',
        acceptState: [
            'new',
            'addressIncomplete',
            'householdIncomplete',
            'shortNotice',
            'option',
            'auto-option',
            'queue',
            'estimate',
            'responseless',
            'cancellation',
            'booked',
            'cancellationWithCosts',
        ],
    },
    {
        id: 'estimate',
        name: {
            nl: 'Schatting zonder datum',
        },
        labelHTML: 'Schatting <br />zonder datum',
        icon: { class: 'calendar-minus' },
        btnStyle: 'primary',
        acceptState: [
            'new',
            'estimate',
            'addressIncomplete',
            'householdIncomplete',
            'shortNotice',
            'responseless',
            'option',
            'auto-option',
            'queue',
            'booked',
            'cancellation',
        ],
    },
    {
        id: 'responseless',
        name: {
            nl: 'Geen antwoord',
        },
        labelHTML: 'Geen <br />antwoord',
        icon: { class: 'times' },
        btnStyle: 'primary',
        acceptState: [
            'new',
            'addressIncomplete',
            'householdIncomplete',
            'label',
            'responseless',
            'queue',
            'auto-option',
            'option',
            'estimate',
            'booked',
        ],
    },
    {
        id: 'booked',
        name: {
            nl: 'Ingeboekt',
        },
        labelHTML: 'Ingeboekt',
        icon: { class: 'calendar-check' },
        btnStyle: 'primary',
        acceptState: ['booked', 'queue', 'auto-option', 'option'],
    },
    {
        id: 'cancellation',
        name: {
            nl: 'Annulering',
        },
        labelHTML: 'Annulering',
        icon: { class: 'ban' },
        btnStyle: 'primary',
        acceptState: [
            'new',
            'cancellation',
            'addressIncomplete',
            'householdIncomplete',
            'shortNotice',
            'responseless',
            'queue',
            'option',
            'auto-option',
            'estimate',
            'booked',
        ],
    },
    {
        id: 'cancellationWithCosts',
        name: {
            nl: 'Annulering met kosten',
        },
        labelHTML: 'Annulering <br />met kosten',
        acceptState: ['booked', 'cancellationWithCosts'],
        btnStyle: 'primary',
        icon: { class: 'euro-sign' },
    },
    {
        id: 'executed',
        name: {
            nl: 'Uitgevoerd',
        },
        labelHTML: 'Uitgevoerd',
        icon: { class: 'truck' },
        acceptState: ['executed'],
        btnStyle: 'primary',
    },
    {
        id: 'completed',
        name: {
            nl: 'Afgerond',
        },
        labelHTML: 'Afgerond',
        icon: { class: 'check' },
        acceptState: ['completed'],
        btnStyle: 'primary',
    },
]

statesTicket.forEach((state) => (state['target'] = { id: 'ticket', name: 'Ticket' }))
statesOrder.forEach((state) => (state['target'] = { id: 'order', name: 'Order' }))

const states = statesOrder.concat(statesTicket).concat(statesMail).concat(locationTypes).concat(orderworkTypes)
const statesObject = {}
states.forEach((state) => (statesObject[state.id] = state))
states.forEach((state) => (state.label = state.labelHTML.replace(`<br />`, ``)))

const companyArray = [
    {
        id: 'svs',
        _id: '5b1d3eae25ebaca717238ed3',
        label: 'Student Verhuis Service',
    },
    {
        id: 'hm',
        _id: '5b1e64188c14551290f57982',
        label: 'Holland Movers',
    },
]

const bootswatchThemes = [
    'Cerulean',
    'Cosmo',
    'Cyborg',
    'Darkly',
    'Flatly',
    'Journal',
    'Litera',
    'Lumen',
    'Lux',
    'Materia',
    'Minty',
    'Morph',
    'Pulse',
    'Quartz',
    'Sandstone',
    'Simplex',
    'Sketchy',
    'Slate',
    'Solar',
    'Spacelab',
    'Superhero',
    'United',
    'Vapor',
    'Yeti',
    'Zephyr',
]

const companyObject = {
    svs: { _id: null },
    hm: { _id: null },
}
companyArray.map((company) => (companyObject[company.id] = company._id))

const changeReasonsObject = {}
changeReasons.forEach((option) => (changeReasonsObject[option.value] = option.label))

const reduceObjFromArray = (arr) =>
    arr.reduce((obj, acc) => {
        obj[acc.value] = acc.name
        return obj
    }, {})

const reduceFullObjFromArray = (arr) =>
    arr.reduce((obj, acc) => {
        obj[acc.value] = acc
        return obj
    }, {})

const templateKindObject = reduceObjFromArray(templateKind)
const availableMaterialObject = reduceObjFromArray(availableMaterial)
const paymentConditionsObject = reduceFullObjFromArray(paymentConditions)
const pinTerminalsObject = reduceFullObjFromArray(pinTerminals)
const templateUniqueRolesObject = reduceObjFromArray(sortBy(templateUniqueRoles, 'name'))
const taxCodesObject = reduceObjFromArray(taxCodes)
const moverTypesObject = reduceObjFromArray(moverTypes)
const communicationPreferencesObject = reduceObjFromArray(communicationPreferences)
const classificationTypesObject = reduceObjFromArray(classificationTypes)
const departmentsObject = reduceObjFromArray(departments)
const limitingFactorTypesObject = reduceFullObjFromArray(limitingFactorTypes)

const userRolesObject = reduceFullObjFromArray(userRoles)

export const Config = Object.freeze({
    bootswatchThemes,

    changeReasons,
    changeReasonsObject,

    userRoles,
    userRolesObject,

    departments,
    departmentsObject,

    statesTicket,
    statesOrder,

    states,
    statesObject,

    companyArray,
    companyObject,

    templateKind,
    templateKindObject,

    templateUniqueRoles,
    templateUniqueRolesObject,

    taxCodes,
    taxCodesObject,

    moverTypes,
    moverTypesObject,

    communicationPreferences,
    communicationPreferencesObject,

    orderworkTypes,
    limitingFactorTypes,
    limitingFactorTypesObject,

    classificationTypes,
    classificationTypesObject,

    availableMaterial,
    availableMaterialObject,

    paymentConditions,
    paymentConditionsObject,

    pinTerminals,
    pinTerminalsObject,

    skillIconObject,

    filterUserSkillRef,

    eventColorClasses,
    getEventColorForCompany,
    getCompanyIdFromIds,
})
