// 2.0.4 Using tactile auth.service
// 2.0.5 Do not use clone request
// 2.0.6 Added service unavailable error
// 2.0.7 Reversed changes
// 2.0.8 Fix error.error.error (cannot find property of)
// 2.0.9 Redirect on "www" to ""
// 2.0.10 Added clipboard module
// 2.1.0 Refresh on NavigationEnd
// 3.0.0 Remove version check

import { Location } from '@angular/common'
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { ClipboardService } from 'ngx-clipboard'
import { ToastrService } from 'ngx-toastr'
import { Observable, of, throwError } from 'rxjs'
import { catchError, filter, map } from 'rxjs/operators'
import { AuthService } from './auth.service'

@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {
    versionUpdateSubscription

    constructor(
        private location: Location,
        private authService: AuthService,
        private toastrService: ToastrService,
        private clipboardService: ClipboardService
    ) {}

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        const authReq = request.clone({
            headers: new HttpHeaders({
                Authorization: this.getToken(),
                'Content-Type': 'application/json',
            }),
        })

        return next.handle(authReq).pipe(
            map((v) => this.map(v)),
            catchError((err) => this.catchErr(err)),
            filter((x) => x !== undefined)
        )
    }

    private map(response: HttpEvent<unknown>) {
        if (!(response instanceof HttpResponse)) return response
        return response
    }

    private catchErr(error: HttpErrorResponse) {
        if (error.error && error.error.decline === 'redirect-to-domain-without-www') {
            window.location.href = window.location.href.replace(/:\/\/www\./i, '://')
            return of(undefined)
        }

        // Error Forbidden
        // You are missing authentication / or there is something wrong
        if (error.status === 401) {
            this.toastrService.error(`Your login is no longer valid`, `Logged out`)
            this.logout()
            return of(undefined)
        }
        if (error.status === 403) {
            this.toastrService.error(`You are not allowed to view this data`, `Forbidden`)
            this.location.back()
            return of(undefined)
        }

        // Network error
        if (error.status === 503) {
            this.toastrService.error(`Service is temporarily not available, please try again on a later moment`, `Service Unavailable`)
            return throwError(() => error)
        }

        if (error.status === 504) {
            this.toastrService.error(`Server unreachable, check your connection and please try again `, `Gateway timeout`)
            return throwError(() => error)
        }

        // 202 Accepted - Your request was fine, we are working on it. Tell where user can find respond
        //
        //
        // 400 - 499 (User error)
        // 401 Unauthorized - No auth present (please try again)
        // 403 Forbidden - I know who you are but you are not allowed
        // 406
        // 409 Conflict
        // 415
        // 429
        // 500 - 599 (Company errors)

        console.log({
            status: error.status,
            error: error.error ? error.error.error : error.error,
            err: error,
        })

        // Show error
        if (error.error && error.error.error) {
            this.toastrService
                .error(error.error.message, 'Internal server error')
                .onTap.subscribe(() => this.clipboardService.copyFromContent(error.error.message))
        }

        if (error.error && error.error.decline) {
            this.toastrService
                .error(error.error.message, 'Not allowed')
                .onTap.subscribe(() => this.clipboardService.copyFromContent(error.error.message))
        }
        throw error
    }

    // Custom
    private logout() {
        this.authService.logout()
    }

    private getToken() {
        const t = this.authService.getToken()
        const tUndefined = t === undefined
        if (tUndefined) throw new Error('Token is not defined')
        return t
    }
}
