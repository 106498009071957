import { Inject, Pipe, PipeTransform } from '@angular/core'
import { BACKEND_URL } from 'main'

@Pipe({
    name: 'image',
    standalone: true,
})
export class ImagePipe implements PipeTransform {
    constructor(@Inject(BACKEND_URL) private backendUrl: string) {}

    public transform(value: string) {
        return `${this.backendUrl}${value}`
    }
}
