const filterUserSkillRef = (userSkillRefs, unix = Date.now()) => {
    return userSkillRefs.filter(
        (skillRef) =>
            new Date(skillRef.skillStart).getTime() <= unix && (!skillRef.skillEnd || new Date(skillRef.skillEnd).getTime() > unix)
    )
}

module.exports = {
    filterUserSkillRef,
}
